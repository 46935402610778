<template>
  <div>
    <ds-header title="服务手册管理"></ds-header>
    <a-form layout="inline">
      <a-form-item label="当前所在手册：">
        <a-select
          show-search
          option-filter-prop="children"
          :filter-option="filterOption"
          style="width: 200px"
          placeholder="请选择"
          v-model="searchForm.bookId"
          @change="handleHandbookChange"
          :allowClear="true"
        >
          <a-select-option v-for="item in manualList" :key="item.bookId">
            {{ item.bookName }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="addManual">
          新增手册
        </a-button>
      </a-form-item>
      <a-form-item v-show="searchForm.bookId">
        <a-button type="primary" @click="editManual">
          编辑手册
        </a-button>
      </a-form-item>
      <a-form-item v-show="searchForm.bookId">
        <a-button type="primary" @click="removeManual">
          删除手册
        </a-button>
      </a-form-item>
      <a-form-item v-show="searchForm.bookId">
        <a-button type="primary" @click="copyLink">
          复制手册链接
        </a-button>
      </a-form-item>
    </a-form>
    <div style="padding-top: 15px">
      <a-button type="primary" @click="showModal()" v-show="searchForm.bookId"> <a-icon type="plus" /> 添加 </a-button>
    </div>
    <div>
      <a-table
        :columns="columns"
        :data-source="data"
        :pagination="table.pagination.total ? table.pagination : false"
        @change="pageChange"
        :rowKey="(record, index) => { return record.id }"
      >
        <template slot="operation" slot-scope="text, record">
          <a href="javascript:;" @click="toView(record.id)">查看</a>
          <a-divider type="vertical" />
          <a v-if="!record.status" href="javascript:;" @click="disableOrEnable(record.id, 1)">启用</a>
          <a v-else href="javascript:;" @click="disableOrEnable(record.id, 0)">禁用</a>
          <a-divider type="vertical" />
          <a href="javascript:;" @click="editDirectory(record.id)">编辑</a>
          <a-divider type="vertical" />
          <a href="javascript:;" @click="deleteById(record.id)">删除</a>
          <a-divider type="vertical" />
          <a href="javascript:;" @click="serviceManualConfig(record.id)">内容配置</a>
          <a-divider type="vertical" />
          <a href="javascript:;" v-if="record.level!==4" @click="addSubdirectory(record.id)">添加子目录</a>
        </template>
      </a-table>
    </div>
<!--    添加、编辑目录-->
    <div>
      <a-modal :forceRender="true" v-model="visible" :title="modalTitle" @ok="handleOk">
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item ref="name" label="当前所在手册">
            {{ manualForm.bookName }}
          </a-form-model-item>
          <a-form-model-item ref="title" label="目录名称" prop="title">
            <a-input
              v-model="form.title"
              placeholder="请输入目录名称（不超过20字）"
              @blur="
          () => {
            $refs.name.onFieldBlur();
          }
        "
            />
          </a-form-model-item>
          <a-form-model-item label="上级目录">
            <a-tree-select
              v-model="form.parentId"
              style="width: 100%"
              :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
              :tree-data="directoryList"
              :disabled="parentDirectoryDisable"
              placeholder="请选择"
              :replaceFields="{
                children: 'children',
                title: 'title',
                key: 'id',
                value: 'id',
              }"
            >
            </a-tree-select>
            <p style="color: red">注：如果为”一级目录“，则无需填写该选项</p>
          </a-form-model-item>
          <a-form-model-item ref="sortNum" label="优先级" prop="sortNum">
            <a-input-number
              v-model="form.sortNum"
              placeholder="请输入"
              :max="9999"
              :min="1"
              :precision="0"
              @blur="
          () => {
            $refs.name.onFieldBlur();
          }
        "
            />
            <p style="color: red">注：优先级（1-9999）优先级越小，展示越靠前</p>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </div>
<!--    添加或修改服务手册弹窗-->
    <div>
      <a-modal :forceRender="true" v-model="show" :title="manualModalTitle" @ok="handleSavaManual">
        <a-form-model
          ref="ruleManualForm"
          :model="manualForm"
          :rules="manualRules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="手册名称" ref="bookName" prop="bookName">
            <a-input
              v-model="manualForm.bookName"
              placeholder="请输入手册名称（不超过12字）"
              :maxLength="12"
              @blur="
          () => {
            $refs.bookName.onFieldBlur();
          }
        "
            />
          </a-form-model-item>
          <a-form-model-item ref="bookType" label="类型" prop="bookType">
            <a-select
              :disabled="disabledType"
              v-model="manualForm.bookType"
              placeholder="请选择类型"
            >
              <a-select-option :value="0">项目手册</a-select-option>
              <a-select-option :value="1">i友期刊</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </div>
  </div>
</template>
<script>
import { projectName } from '@/api/modularManagement'
import {
  getList,
  findOneServiceManualById,
  insertServiceManual,
  updateServiceManual,
  getAllParentDirectory,
  getManualList, addManual,
  removeCurrentManual,
  getManualByBookId, updateServiceManualContent
} from '@/api/serviceManualManagement'
import moment from "moment/moment";
const columns = [
  {
    title: '目录名称',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: '优先级',
    dataIndex: 'sortNum',
    key: 'sortNum',
  },
  {
    title: '状态',
    dataIndex: 'status',
    customRender(text) {
      return text ? '启用' : '禁用';
    },
  },
  {
    title: '更新时间',
    dataIndex: 'updateTime',
    customRender(text) {
      return text ? moment(text).format("YYYY-MM-DD HH:mm:ss") : "-";
    },
  },
  {
    title: '操作人',
    dataIndex: 'updateBy',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
  },
];
export default {
  data() {
    return {
      // 防抖
      timer: null,
      // 当前手册的id
      currentBookId: '',
      // 所有手册数据
      manualList: [],
      // 新增或修改手册的弹窗title
      manualModalTitle: '',
      // 服务手册的添加或修改--> 弹窗
      show: false,
      modalTitle: '添加目录',
      labelCol: { span: 5 },
      wrapperCol: { span: 18 },
      directoryList: [],
      other: '',
      currentProject: {},
      // 手册类型是否可编辑 ===> 编辑的时候disabled
      disabledType: false,
      // 手册form
      manualForm: {
        // 服务手册id
        bookId: '',
        // 手册名称
        bookName: '',
        // 手册类型
        bookType: ''
      },
      form: {
        id: '',
        // 目录名称
        title: '',
        // 上级目录
        parentId: '',
        // 优先级
        sortNum: undefined
      },
      rules: {
        title: [
          { required: true, message: '请填写目录名称', trigger: 'blur' },
          { required: true, max: 20, message: '目录名称不能超过20字符', trigger: 'blur' },
        ],
        sortNum: [
          { required: true, message: '请填写优先级', trigger: 'blur' },
        ]
      },
      manualRules: {
        bookName: [
          { required: true, message: '请输入手册名称', trigger: 'blur' },
          { required: true, max: 12, message: '手册名称不能超过12字符', trigger: 'blur' }
        ],
        bookType: [
          { required: true, message: '请选择类型', trigger: 'change' }
        ]
      },
      // 所有项目
      projectList: [],
      searchForm: {
        bookId: undefined
      },
      visible: false,
      columns,
      data: [],
      parentDirectoryDisable: false,
      table: {
        //分页数据
        pagination: {
          showSizeChanger: true,
          current: 1,
          pageSize: 10,
          total: 0,
          showQuickJumper: true,
          showTotal: (total) => {
            return `共 ${total} 条`;
          },
        },
      }
    }
  },
  async created() {
    // 获取所有项目
    await this.getProjectList();
    // 查询所有手册
    await this.getAllManualList();
  },
  methods: {
    // 查询所有手册
    async getAllManualList() {
      const res = await getManualList();
      const { code, data, msg } = res
      if (code === '200') {
        this.manualList = data
        const currentBook = data.find(item => item.bookId === this.currentBookId)
        if (currentBook) {
          this.searchForm.bookId = currentBook.bookId
          this.handleHandbookChange(currentBook.bookId)
        }
      } else {
        this.$message.error(msg)
      }
    },
    // 去新增或修改手册
    addManual() {
      this.resetManualForm()
      this.manualModalTitle = '新增手册'
      this.disabledType = false
      this.show = true
    },
    async editManual() {
      this.manualModalTitle = '编辑手册'
      const res = await getManualByBookId(this.searchForm.bookId)
      const { code, data, msg } = res
      if(code === '200') {
        this.manualForm = data
        this.show = true
        this.disabledType = true
      } else {
        this.$message.warning(msg)
      }
    },
    // 获取当前手册信息
    async getManualInfoById() {
      const res = await getManualByBookId(this.searchForm.bookId)
      const { code, data, msg } = res
      if(code === '200') {
        this.manualForm = data
      } else {
        this.$message.warning(msg)
      }
    },
    // 删除手册
    async removeManual() {
      const that = this
      await this.$confirm({
        title: '是否确认删除该手册及手册下的内容？',
        content: h => <div style="color:red;">注:该操作不支持撤销，请谨慎操作</div>,
        async onOk() {
          const res = await removeCurrentManual(that.searchForm.bookId);
          const {code, msg} = res
          if (code === '200') {
            that.data.length = 0
            that.searchForm.bookId = ''
            that.currentBookId = ''
            await that.getAllManualList()
          } else {
            that.$message.warning(msg)
          }
        },
        onCancel() {
          console.log('Cancel');
        },
        class: 'test',
      });
    },
    // 重置手册form表单
    resetManualForm() {
      this.manualForm.bookName = ''
      this.manualForm.bookId = ''
      this.manualForm.bookType = ''
    },
    // 下拉框筛选
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    // 获取项目
    async getProjectList() {
      // const res = await projectName()
      // const { code, data: {items} } = res
      // if (code === '200') {
      //   this.projectList = items
      //   const project = this.projectList.find(item => item.project_name.includes('东升科技园'));
      //   this.searchForm.projectId = this.$store.state.serviceManual.currentProject.projectId || project.id
      //   this.currentProject = this.$store.state.serviceManual.currentProject ?
      //     this.$store.state.serviceManual.currentProject: {projectId: project.id, projectName: project.project_name}
      //   this.$store.commit('serviceManual/SAVE_PROJECT',this.currentProject)
      //   await this.getServiceManualList()
      // }
      this.currentBookId = this.$store.state.serviceManual.currentHandbookId || '';
    },

    // 获取所有父级目录
    async getAllParentDirectory() {
      const res = await getAllParentDirectory(this.searchForm.bookId)
      const { code, data } = res
      if (code === '200') {
        this.directoryList = data
      }
    },
    // 编辑
    async editDirectory(id) {
      this.parentDirectoryDisable = true
      this.visible = true
      this.$nextTick(async () => {
        await this.resetForm()
      })
      await this.getAllParentDirectory();
      await this.getManualInfoById()
      this.modalTitle = '编辑目录'
      const res = await findOneServiceManualById(id)
      const { code, data } = res
      if (code === '200') {
        this.form = data
      }
    },
    // 项目change事件
    handleHandbookChange(e) {
      this.table.pagination.current = 1
      this.getServiceManualList();
      this.data.length = 0
      this.$store.commit('serviceManual/SAVE_CURRENT_HANDBOOK_ID', e)
    },

    // 添加子目录
    async addSubdirectory(id) {
      this.modalTitle = '添加目录'
      this.parentDirectoryDisable = false
      await this.getAllParentDirectory();
      await this.getManualInfoById()
      this.form.id = ''
      this.form.parentId = id
      this.form.title = ''
      this.form.sortNum = undefined
      this.visible = true
      this.$nextTick(async () => {
        await this.resetForm()
      })
    },
    // 列表
    async getServiceManualList() {
      if (!this.searchForm.bookId) {
        return false
      }
      const res = await getList(this.searchForm.bookId, this.table.pagination.current, this.table.pagination.pageSize);
      const { code, data: {list, total} } = res
      if (code === '200') {
        this.data = list
        this.table.pagination.total = total
      }
    },
    // 删除
    async deleteById(id) {
      let that = this
      const params = {
        id: id,
        deleted: 1
      }
      await this.$confirm({
        title: '删除操作将会删除该目录及其内容&子目录及其内容，确定是否删除？',
        async onOk() {
          const res = await updateServiceManualContent(params)
          const {code} = res
          try {
            if (code === '200') {
              that.$message.success('操作成功')
              that.table.pagination.current = 1
              await that.getServiceManualList()
            }
          } catch (e) {
            this.$message.error(e.msg)
          }
        },
        onCancel() {
          console.log("Cancel");
        },
        class: "test",
      });
    },

    //分页操作
    pageChange(option) {
      this.table.pagination.current = option.current;
      this.table.pagination.pageSize = option.pageSize;
      this.getServiceManualList();
    },
    // 弹框
    async showModal() {
      this.$nextTick(async () => {
        this.visible = true;
        await this.resetForm()
        this.form.id = ''
        this.form.parentId = ''
        this.form.title = ''
        this.form.sortNum = undefined
      })
      await this.getManualInfoById()
      this.modalTitle = '添加目录'
      await this.getAllParentDirectory()
      this.parentDirectoryDisable = false
    },
    // 保存或编辑服务手册
    handleSavaManual() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      } else {
        this.timer = setTimeout(() => {
          this.toSave()
        }, 500)
      }

    },
    // 去保存手册数据
    toSave() {
      this.$refs.ruleManualForm.validate(async valid => {
        if(valid) {
          let res = {}
          if(this.manualForm.bookId) {
            res = await updateServiceManual(this.manualForm)
          } else {
            res = await addManual(this.manualForm)
            this.data.length = 0
          }
          const { code, msg, data } = res
          if(code === '200') {
            this.timer = null
            this.$message.success('操作成功！')
            // 新增手册成功后要定位到当前新增的手册上
            if(!this.manualForm.bookId) {
              this.currentBookId = data
              await this.getAllManualList()
              this.searchForm.bookId = data
              this.$store.commit('serviceManual/SAVE_CURRENT_HANDBOOK_ID', data)
            }
            await this.getServiceManualList()
            this.show = false
          } else {
            this.$message.error(msg)
          }
        } else {
          return false
        }
      })
    },
    // 保存
    handleOk() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          if (this.form.id) {
            const res = await updateServiceManualContent({...this.form, handBookId: this.manualForm.bookId})
            const { code } = res
            if (code === '200') {
              this.$message.success('操作成功')
              this.visible = false
              await this.getServiceManualList()
            } else {
              this.$message.error("操作失败")
            }
          } else {
            const params = {
              ...this.form,
              handBookId: this.manualForm.bookId
            }
            const res = await insertServiceManual(params)
            const { code } = res
            if (code === '200') {
              this.$message.success('操作成功')
              this.visible = false
              await this.getServiceManualList()
            } else {
              this.$message.error("添加失败")
            }
          }
        } else {
          return false;
        }
      });
    },
    // 重置
    async resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    // 查看
    toView(id) {
      this.$router.push({
        path: '/serviceManualManagement/serviceManual/view',
        query: {
          id: id
        }
      })
    },
    // 服务手册配置
    serviceManualConfig(id) {
      this.$router.push({
        path: '/serviceManualManagement/serviceManual/configServiceManual',
        query: {
          id: id
        }
      })
    },
    // 禁用or启用
    disableOrEnable(id, status) {
      let that = this
      this.$confirm({
        title: `确定要${status ? '启用' : '禁用'}该条数据吗？`,
        async onOk() {
          let params = {
            id: id,
            status: status
          }
          const res = await updateServiceManualContent(params);
          const { code } = res
          if (code === '200') {
            that.$message.success('操作成功')
            await that.getServiceManualList()
          }
        },
        onCancel() {
          console.log("Cancel");
        },
        class: "test",
      });
    },
    // 服务链接
    copyLink () {
      const env = process.env.NODE_ENV
      let url = '';
      if (env === 'production') {
        url = `https://yuanqu.innoecos.cn/apps/activity/serviceManual?bookId=${this.searchForm.bookId}`
      } else {
        url = `https://yuanqu-sit.innoecos.cn/apps/activity/serviceManual?bookId=${this.searchForm.bookId}`
      }
      let oInput = document.createElement('input')
      oInput.value = url
      document.body.appendChild(oInput)
      oInput.select() // 选择对象;
      document.execCommand('Copy') // 执行浏览器复制命令
      this.$message.success("复制成功！")
      oInput.remove()
    }
  }
}
</script>
<style scoped lang="scss">

</style>
