import { fetchApi } from "@/utils/axios";
import URL from "@/api/urlConfig"

const prefix = '/api/dscloud-appservice-management'

/**
 * 服务手册
 */
/**
* @description 服务手册列表
* @author cxd
* @param
* @time 2023-05-25 10:33:36
*/
export const getList = (id, pageNum, pageSize) => {
  const data = {
    pageNo: pageNum,
    pageSize,
    handBookId: id
  }
  return fetchApi(prefix + '/web/service-book/findByHandBookId', data, 'POST', 'data');
}

/**
* @description 禁用或启用
* @author cxd
* @param
* @time 2023-05-25 10:37:09
*/
export const serviceManualDisableOrEnable = () => {
  return fetchApi(URL.SERVICE_MANUAL_DISABLED_ENABLED)
}

/**
* @description 删除
* @author cxd
* @param
* @time 2023-05-25 10:40:59
*/
export const serviceManualDeleteById = (id) => {
  return fetchApi(URL.UPDATE_SERVICE_MANUAL, 'data',)
}
/**
* @description 详情
* @author cxd
* @param id
* @time 2023-05-25 10:43:25
*/
export const findOneServiceManualById = (id) => {
  return fetchApi(prefix + `/web/service-book/findById/${id}`)
}
/**
* @description 添加服务手册
* @author cxd
* @param
* @time 2023-05-25 10:46:04
*/
export const insertServiceManual = (data) => {
  console.log(data, 'data')
  return fetchApi(URL.INSERT_SERVICE_MANUAL, data, 'POST', 'data')
}
/**
* @description 编辑服务手册
* @author cxd
* @param
* @time 2023-05-25 10:49:21
*/
export const updateServiceManual = (data) => {
  return fetchApi(prefix + '/web/handBook/update', data, 'put', 'data');
}

/**
* @description 服务配置
* @author cxd
* @param
* @time 2023-06-12 09:36:25
*/
export const serviceManualConfig = (data) => {
  return fetchApi(URL.SERVICE_MANUAL_CONFIG, data, 'POST', 'data');
}

/**
* @description 获取所有父级目录
* @author cxd
* @param
* @time 2023-06-08 09:25:06
*/
export const getAllParentDirectory = (id) => {
  const params = {
    handBookId: id
  }
  return fetchApi(prefix + `/web/service-book/listAllParent`, params, 'post', 'data')
}

/**
 * 查询所有手册
 */
export const getManualList = () => {
  return fetchApi(prefix + '/web/handBook/findByName', {}, 'post', 'data')
}

/**
 *  添加手册
 */
export const addManual = (data) => {
  return fetchApi(prefix + '/web/handBook/add', data, 'POST', 'data')
}

/**
 * 删除手册
 */
export const removeCurrentManual = (bookId) => {
  const params = {
    bookId
  }
  return fetchApi(prefix + '/web/handBook/delete', params, 'DELETE', 'data')
}

/**
 * 通过bookId查询手册
 * */
export const getManualByBookId = (id) => {
  const params = {
    bookId: id
  }
  return fetchApi(prefix + '/web/handBook/detail', params, 'get', 'params')
}

/**
 * 获得iyou周刊的列表
 */
export const getIyouPeriodicalList = (data) => {
  return fetchApi(prefix + '/web/handBookBinding/list', data, 'post', 'data')
}

/**
 * 手册目录修改
 */
export const updateServiceManualContent = (data) => {
  return fetchApi(prefix + '/web/service-book/update', data, 'post', 'data')
}

/**
 * 查询所有可绑定的手册
 */
export const findAllEnableBooks = () => {
  return fetchApi(prefix + '/web/handBook/findBindingBook')
}

/**
 * 新增绑定手册
 */
export const addBindBook = (data) => {
  return fetchApi(prefix + '/web/handBookBinding/add', data, 'post', 'data')
}

/**
 * 修改绑定的期刊状态
 */
export const updateBindingBookStatus = (data) => {
  return fetchApi(prefix + '/web/handBookBinding/updateStatus', data, 'put', 'data')
}

/**
 * 删除绑定的期刊
 */
export const removeBindingBook = (id) => {
  const params = {
    bookBindingId: id
  }
  return fetchApi(prefix + '/web/handBookBinding/delete', params, 'delete', 'data')
}

/**
 * 获取绑定的期刊的详情
 */
export const findBindingById = (id) => {
  const params = {
    bookBindingId: id
  }
  return fetchApi(prefix + '/web/handBookBinding/detail', params, 'get', 'params')
}

/**
 * 修改绑定期刊的数据
 */
export const updateBindingBook = (data) => {
  return fetchApi(prefix + '/web/handBookBinding/update', data, 'put', 'data')
}
